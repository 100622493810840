/* global Office */

export async function readEmailBody(): Promise<string> {
  return new Promise<string>((resolve, reject) => {
    try {
      Office.context.mailbox.item.body.getAsync("text", (result: Office.AsyncResult<string>) => {
        if (result.status === Office.AsyncResultStatus.Succeeded) {
          resolve(result.value);
        } else {
          reject(`Error retrieving email body: ${result.error.message}`);
        }
      });
    } catch (error) {
      reject(`Error: ${error}`);
    }
  });
}