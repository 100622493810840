/* global Office */

import React from "react";
import { Button } from "@fluentui/react-components";
import { ResponseOption } from "../backendResponse";

interface EmailButtonProps {
    response: ResponseOption; // Text, der in die Antwort kopiert werden soll
}

const ReplyEmailButton: React.FC<EmailButtonProps> = (props: EmailButtonProps) => {

    const handleReplyClick = async () => {
        const formattedText = props.response.ResponseMessage.replace(/\n/g, "<br>");
        const formattedText2 = formattedText.replace(/\[Doc.*$/, "");

        Office.context.mailbox.item.displayReplyForm({
            htmlBody: 
            `
            <div style="white-space: nowrap">
                ${formattedText2}
            </div>
            `
        });
    };

    return (
        <Button appearance="primary" size="large" onClick={handleReplyClick}>
            Vorschlag als Antwort übernehmen
        </Button>
    );
};

export default ReplyEmailButton;
