import React from "react";
import { makeStyles } from "@fluentui/react-components";

interface ReadOnlyTextBlockProps {
    text: string; // Text, der angezeigt werden soll}
};
const useStyles = makeStyles({
    readOnlyTextField: {
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "4px",
        maxHeight: "300px", // Maximale Höhe des Textfelds
        overflowY: "auto",  // Vertikales Scrollen aktivieren
        whiteSpace: "pre-wrap", // Beibehalten von Zeilenumbrüchen
        wordWrap: "break-word", // Wörter umbrechen, wenn sie zu lang sind
    }
});
const ReadOnlyTextField: React.FC<ReadOnlyTextBlockProps> = (props: ReadOnlyTextBlockProps) => {

    const styles = useStyles();

    return (
        <div className={styles.readOnlyTextField}>
            {props.text || "Kein Text verfügbar."} {/* Platzhalter, wenn kein Text vorhanden ist */}
        </div>
    );
};

export default ReadOnlyTextField;
