export class HttpError extends Error {
    public statusCode: number;
    public responseText: string;
  
    constructor(message: string, statusCode: number, responseText: string) {
        const trueProto = new.target.prototype;
        super(message); // Setzt die Fehlernachricht
        this.name = 'HttpError'; // Setzt den Namen des Fehlers
        this.statusCode = statusCode; // Speichert den HTTP-Statuscode
        this.responseText = responseText; // Speichert den Antworttext
        Object.setPrototypeOf(this, trueProto);
    }
  }