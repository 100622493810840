/* global Office */

import React, { useState, useEffect} from "react";
import { Button } from "@fluentui/react-components";
import { ResponseOption } from "../backendResponse";

interface EmailButtonProps {
    response: ResponseOption; // Text, der in die Antwort kopiert werden soll
}

const ReplyEmailButtonAll: React.FC<EmailButtonProps> = ({ response }) => {

    const handleReplyClick = async () => {
        const formattedText = response.ResponseMessage.replace(/\n/g, "<br>");
        const formattedText2 = formattedText.replace(/\[Doc.*$/, "");

        Office.context.mailbox.item.displayReplyAllForm({
            htmlBody: 
            `
            <div 
            style="white-space: pre-wrap; font-family: Calibri; font-size: inherit;">
                ${formattedText2}
            </div>
            `
        });
    };

    return (
        <Button appearance="primary" size="large" onClick={handleReplyClick}>
            Vorschlag als Antwort an Alle übernehmen
        </Button>
    );
};

export default ReplyEmailButtonAll;
