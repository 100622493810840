import * as React from "react";
import Header from "./Header";
import { makeStyles } from "@fluentui/react-components";
import { useState } from "react";
import SendTextToBackendButton from "./SendTextToBackendButton";
import DynamicForm from "./BoxButtonIteration";
import { BackendResponse } from "../backendResponse";
import Errorbox from "./Errorbox";

interface AppProps {
  title: string;
  getAccessToken: () => Promise<string>;
}

const useStyles = makeStyles({
  root: {
    minHeight: "100vh",
    flexDirection: "column"
  },
});

const App: React.FC<AppProps> = (props: AppProps) => {
  //const [responseText, setResponseText] = useState<string>("");
  const [backendResponse, setResponse] = useState<Partial<BackendResponse>>();
  const [title, setTitle] = useState<string>("Was kann ich für dich tun?");
  const [error, setError] = useState<Error>();

  // const handleNewResponseOption = (newResponse: string) => {
  //   setResponseText(newResponse);
  // }
  const handleResponseUpdate = (newResponse: Partial<BackendResponse>) => {
    setResponse(newResponse);
    setTitle(newResponse.GeneralResponse);
  }
  const handleError = (error: Error) => {
    setError(error);
  }

  const styles = useStyles();

  return (
    <div className={styles.root}>
      <Header logo="assets/image.png" title={props.title} message={title} />
      <SendTextToBackendButton onResponseUpdate={handleResponseUpdate} onError={handleError} getAccessToken={props.getAccessToken} />
      {backendResponse && <DynamicForm items={backendResponse.ResponseOptions} />}
      {error && <Errorbox error={error} />}
    </div>
    
  );
  // return (
  //   <div className={styles.root}>
  //     <Header logo="assets/image.png" title={props.title} message="Welcome" />
  //     <SendTextToBackendButton onResponseUpdate={handleResponseUpdate} />
  //     {transformedResponse && <TextCarousel transformedResponse={transformedResponse} updateResponseText={handleNewResponseOption}/>}
  //     {transformedResponse && <ButtonArrangement responseText={responseText} />}
  //   </div>
    
  // );
  // return (
  //   <div className={styles.root}>
  //     <Header logo="assets/logo-filled.png" title={props.title} message="Welcome" />
  //     <TextFieldDynamic updateUserText={handleTextUpdate} text={text}/>
  //     <ButtonArrangement onTextUpdate={handleTextUpdate} onResponseUpdate={handleResponseUpdate} userInputText={text}/>
  //     <ReadOnlyTextField text ={transformedResponse.GeneralResponse}/>
  //     <ReadOnlyTextField text = {transformedResponse.Intent}/>
  //     <TextCarousel transformedResponse={transformedResponse} updateResponseText={handleResponseOption}/>
  //     <ReplyEmailButton responseText={responseText} />
  //   </div>
  // );
};

export default App;
