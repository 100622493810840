import * as React from "react";
import { makeStyles } from "@fluentui/react-components";
import ReplyEmailButtonAll from "./ReplyEmailButtonAll";
import ReplyEmailButton from "./ReplyEmailButton";
import { ResponseOption } from "../backendResponse";

interface ResponseText {
    response: ResponseOption;
}


const useStyles = makeStyles({
  container: {
    display: "flex",       // Flexbox verwenden, um die Komponenten nebeneinander anzuordnen
    flexDirection: "row",  // Horizontale Anordnung
    gap: "20px",           // Abstand zwischen den Komponenten
    padding: "0 0px",
    justifyContent: "center",
    alignItems: "flex-start"
  },
});

const MainComponent: React.FC<ResponseText> = (props: ResponseText) => {

    const styles = useStyles();

    return (
    <div className={styles.container}>
        <ReplyEmailButton response={props.response} />
        <ReplyEmailButtonAll response={props.response} />
    </div>
    );
};

export default MainComponent;
