import * as React from "react";
import { createRoot } from "react-dom/client";
//hier werden die definierten tsx-dateien im components-ordner geladen
import App from "./components/App";
import { FluentProvider, webLightTheme } from "@fluentui/react-components";
import { createNestablePublicClientApplication, IPublicClientApplication } from "@azure/msal-browser";
import { envConfig } from "./config/environment";

/* global document, Office, module, require, HTMLElement */

const title = "QES-Chatbot Add-in";

const rootElement: HTMLElement | null = document.getElementById("container");
const root = rootElement ? createRoot(rootElement) : undefined;

let pca: IPublicClientApplication;

let getAccessToken = async () => {
  // Specify minimum scopes needed for the access token.
  const tokenRequest = {
    scopes: [`api://${envConfig.auth.clientId}/.default`],
  };
  let accessToken = null;

  try {
    console.log("Trying to acquire token silently...");
    const userAccount = await pca.acquireTokenSilent(tokenRequest);
    console.log("Acquired token silently.");
    accessToken = userAccount.accessToken;
  } catch (error) {
    console.log(`Unable to acquire token silently: ${error}`);
  }

  if (accessToken === null) {
    // Acquire token silent failure. Send an interactive request via popup.
    try {
      console.log("Trying to acquire token interactively...");
      const userAccount = await pca.acquireTokenPopup(tokenRequest);
      console.log("Acquired token interactively.");
      accessToken = userAccount.accessToken;
    } catch (popupError) {
      // Acquire token interactive failure.
      console.log(`Unable to acquire token interactively: ${popupError}`);
    }
  }

  // Log error if both silent and popup requests failed.
  if (accessToken === null) {
    console.error(`Unable to acquire access token.`);
    return;
  }

  return accessToken;
}

/* Render application after Office initializes */
Office.onReady(async (info) => {
  if (info.host) {    
    // Initialize the public client application
    pca = await createNestablePublicClientApplication({
      auth: {
        clientId: envConfig.auth.clientId,
        authority: `https://login.microsoftonline.com/${envConfig.auth.tenantId}/`,
        supportsNestedAppAuth: true
      }
    });

    root?.render(
      <FluentProvider theme={webLightTheme}>
        <App title={title} getAccessToken={getAccessToken} />
      </FluentProvider>
    );
  }
});

if ((module as any).hot) {
  (module as any).hot.accept("./components/App", () => {
    const NextApp = require("./components/App").default;
    root?.render(NextApp);
  });
}
