import { makeStyles } from "@fluentui/react-components";
import ReadOnlyTextField from "./ReadOnlyTextField";
import React from "react";
import ButtonArrangement from "./ButtonArrangement";
import { ResponseOption } from "../backendResponse";

interface DynamicFormProps {
    items: ResponseOption[];
  }

const useStyles = makeStyles({
    element: {
        marginBottom: '1em' 
    },
    option_title: {
        textAlign: "center",
        marginBottom: "20px"
    },
});

const DynamicForm: React.FC<DynamicFormProps> = ({ items }) => {
    const styles = useStyles();
    return (
        <div>
        {items.map((item, index) => (
            <div key={index} className={styles.element}>
                <h2 className={styles.option_title}>Option {index + 1}</h2>
                <ReadOnlyTextField text = {item.ResponseMessage}/>
                <ButtonArrangement response={item} />
            </div>
        ))}
        </div>
    );
};

export default DynamicForm;
  