import * as React from "react";
import { Button, makeStyles } from "@fluentui/react-components";
import { BackendResponse } from "../backendResponse";
import { readEmailBody } from "../email";
import { useState } from "react";
import { HttpError } from "../httpError";
import { envConfig } from "../config/environment";

interface SendEmail {
    // onTextUpdate: (text: string) => Promise<string>;
    onResponseUpdate: (text: Partial<BackendResponse>) => void;
    onError: (error: Error) => void;
    getAccessToken: () => Promise<string>;
  } 

const useStyles = makeStyles({
    buttonFormatting: {
      display: "flex",
      justifyContent: "center"
    },
  });

const SendTextToBackendButton: React.FC<SendEmail> = (props: SendEmail) => { 
    const [button, setActionButton] = useState<boolean>(false); // Aktueller Index des Textblocks
    const [status, setActionStatus] = useState<string>("Antwortvorschläge erzeugen");

    const styles = useStyles();

    const sendTextToBackend = async () => {
        setActionButton(true);
        setActionStatus("Processing");
        try{
          const response = await callBackend();
          const data: BackendResponse = await response.json();
          const responseData: Partial<BackendResponse> = {
            GeneralResponse: data.GeneralResponse,
            Intent: data.Intent,
            Citations: data.Citations,
            ResponseOptions: data.ResponseOptions
          }
          await props.onResponseUpdate(responseData);
        }
        catch(error){
          var customErrorMessage: string = "";
          if(error instanceof HttpError)
          {
            customErrorMessage = "Backend error";
            console.error(customErrorMessage, error);
          }
          else{
            customErrorMessage = "Fehler beim Parsen des Antworttextes.";
            console.error(customErrorMessage, error);
          }
          await props.onError(error);
        }
        setActionButton(false);
        setActionStatus("Antwortvorschläge erzeugen");
      };

    const callBackend = async (): Promise<Response> => {
      
      let accessToken = await props.getAccessToken();
      const emailBody = await readEmailBody();
        //query versionsnummer
      const response = await fetch(`${envConfig.api.baseUrl}/FunctionGptProcessing`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`
          //versionierung auch im header möglich, für alle custom headers x davor
          //"x-version": "2024-11-12"
          },
        body: emailBody
      })
      if (!response.ok) {
        throw new HttpError(response.status.toString(), response.status, response.statusText);
      }
      return response;
    }
  
    return (
        <div className={styles.buttonFormatting}>
        <Button appearance="primary" disabled={button} size="large" onClick={sendTextToBackend}>
           {status} 
        </Button>
        </div>
    );
};

export default SendTextToBackendButton;